import PostListBase from './PostListBase';
import PostCard from './PostCard';

const cardComponent = (key, data, readMore) => (
  <PostCard key={key} data={data} readMore={readMore} />
);

const PostList = ({ data, pathname, pageContext, title, readMore }) => (
  <PostListBase
    data={data}
    pathname={pathname}
    pageContext={pageContext}
    title={title}
    readMore={readMore}
    cardComponent={cardComponent}
  />
);

export default PostList;
